import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Application from "../components/todolist/application";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Metatags title="Todo Boards | May 12 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-12.png" url="https://cssgrid31.brett.cool" pathname="/may-12" mdxType="Metatags" />
    <Application mdxType="Application" />
    <Footer date={12} prev={true} next={true} mdxType="Footer">
      <p>{`Ah Todo lists. I would never be able to adequately adult without them. My todolist of choice is currently `}<a parentName="p" {...{
          "href": "https://culturedcode.com/things/"
        }}>{`Things 3`}</a>{` (though I have to say that `}<a parentName="p" {...{
          "href": "https://www.taskade.com/"
        }}>{`Taskade`}</a>{` looks pretty nifty).`}</p>
      <p>{`In this non-functional prototype, I wanted to explore the idea of having different to-do lists grouped together, a bit like Trello, focused on information density, allowing you to see outstanding todos across different focuses, without having to switch contexts (though some scrolling would be required).`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      